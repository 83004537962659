import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import phoneIcon from '../assets/icons/phoneIcon.svg'
import emailIcon from '../assets/icons/emailIcon.svg'
import anthonyT from '../images/team/anthony-t.jpg'
import andyT from '../images/team/andy-t.jpg'
import antonioM from '../images/team/antonio-m.jpg'
import danteD from '../images/team/dante-d.jpg'
import davidM from '../images/team/david-m.jpg'
import frankO from '../images/team/frank-o.jpg'
import giancarloF from '../images/team/giancarlo-f.jpg'
import ivanI from '../images/team/ivan-i.jpg'
import johnR from '../images/team/john-r.jpg'
import anthonyD from '../images/team/anthonyd.jpg'
import mercedesC from '../images/team/mercedes-c.jpg'
import raffaeleA from '../images/team/raffaele-a.jpg'
import sarahF from '../images/team/sarah.jpg'
import steveK from '../images/team/steve-k.jpg'
import tamaraA from '../images/team/tamara-a.jpg'
import tellyP from '../images/team/telly-p.jpg'
import staceyG from '../images/team/stacey-g.jpg'
import bita from '../images/team/bita.jpg'
import mariaL from '../images/team/mariaL.jpg'
import Armin from '../images/team/Armin.jpeg'
import diana from '../images/team/diana.jpg'
import melanie from '../images/team/melanieChung.jpg'
import max from '../images/team/max-a.jpg'
import avatar from '../images/team/avatar.png'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Modal from 'react-modal'

import GoldAntler20 from "../images/Gold-Antler-2020.svg"
import GoldAntler21 from "../images/Gold-Antler-Award-2021.svg"
import CommercialAntler21 from "../images/Commercial-Antler-Award-2021.svg"
import CommercialAntler20 from "../images/Commercial-Antler-Award-2020.svg"
import PlatinumAntler from "../images/Platinum-Antler-Award.svg"
import PlatinumAntler21 from "../images/Platinum-Antler-Award-2021.svg"
import EllicottElite from "../images/Ellicott-Elite-Award-2020.svg"
import EllicottElite21 from "../images/Ellicott-Elite-Award-2021.svg"
import EllicottCavalier from "../images/Ellicott-Cavalier-Award-2021.svg"
import GoldAward from "../images/Commercial.jpeg"
import BlackAward from "../images/Black-Antler.jpeg"


const OurPromiseWrapper = styled.div`
  margin: auto;
  padding: 10em;
  display: flex;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.1),
      rgba(6, 6, 6, 0.23)
    ),
    url(https://northwhick.netlify.app/ellicot-assets/contact/ContactHero.jpg);
  background-size: cover;
`

const CTATitle = styled.h2`
  font-family: CentraNo2-Bold;
  color: #fff;
  letter-spacing: 1px;
  font-size: 3.75rem;
  text-align: center;
`

const CTAWrapper = styled.div`
  max-width: 90%;
  margin: auto;
`

const CardText = styled.p`
  font-family: CentraNo2-Book;
  font-size: 0.8em;
  margin: 2em 1em;
`

const TeamImg = styled.img`
  max-width: 90%;
  object-fit: cover;
`

export const Team: React.FunctionComponent = () => {

  const [signupIsOpen, setSignupIsOpen] = React.useState(false)
  const [signupIsOpenTwo, setSignupIsOpenTwo] = React.useState(false)
  const [signupIsOpenThree, setSignupIsOpenThree] = React.useState(false)
  const [signupIsOpenFour, setSignupIsOpenFour] = React.useState(false)
  const [signupIsOpenFive, setSignupIsOpenFive] = React.useState(false)
  const [signupIsOpenSix, setSignupIsOpenSix] = React.useState(false)
  const [signupIsOpenSeven, setSignupIsOpenSeven] = React.useState(false)

  const openSignup = () => {
    setSignupIsOpen(true)
  }

  const closeSignup = () => {
    setSignupIsOpen(false)
  }


  const openSignupTwo = () => {
    setSignupIsOpenTwo(true)
  }

  const closeSignupTwo = () => {
    setSignupIsOpenTwo(false)
  }

  const openSignupThree = () => {
    setSignupIsOpenThree(true)
  }

  const closeSignupThree = () => {
    setSignupIsOpenThree(false)
  }


  const openSignupFour = () => {
    setSignupIsOpenFour(true)
  }

  const closeSignupFour = () => {
    setSignupIsOpenFour(false)
  }


  const openSignupFive = () => {
    setSignupIsOpenFive(true)
  }

  const closeSignupFive = () => {
    setSignupIsOpenFive(false)
  }

  const openSignupSix = () => {
    setSignupIsOpenSix(true)
  }

  const closeSignupSix = () => {
    setSignupIsOpenSix(false)
  }

  const openSignupSeven = () => {
    setSignupIsOpenSeven(true)
  }

  const closeSignupSeven = () => {
    setSignupIsOpenSeven(false)
  }

  let AOS;
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos");
    AOS.init({
      once: true,
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });
  return (
    <div style={{ background: '#eee', padding: '5em 0' }}>
      <CTAWrapper className="teamMemberRow">
        <FlexDiv
          style={{ flexFlow: 'nowrap' }}
          className="teamMemberRowContainer"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="2000"
        >
          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={avatar} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Alex Rust</h3>
                  <p className="teamTitle"> Broker</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:ar@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={avatar} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Alina Meyer</h3>
                  <p className="teamTitle"> Sales Representative</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:alinameyer@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>

          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={andyT} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Andy Tersigni</h3>
                  <p className="teamTitle"> Owner</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        className="phoneNoWrap"
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>{' '}
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        className="contactWrapperText"
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:andy@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                      <FlexDiv className="bioReadBtn" onClick={openSignupTwo}><span>View Bio</span></FlexDiv>
                      <Modal isOpen={signupIsOpenTwo} onRequestClose={closeSignupTwo} className={'Modal23 bioModal'}>
                        <TeamImg src={andyT} className="memberPhoto" />
                        <h2>ANDY TERSIGNI</h2>
                        <small>Owner</small>
                        <hr />
                        <FlexDiv
                          style={{ marginTop: '1em' }}
                          className="teamCardBrk modalContactWrapTitle"
                        >
                          <FlexDiv
                            className="phoneNoWrap"
                            style={{
                              flexFlow: 'column',
                              alignSelf: 'center',
                              padding: '0.75em 0.25em 0.9em',
                            }}
                          >
                            <FlexDiv>
                              <span className="">
                                <a
                                  href="tel:416-213-7447"
                                  style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                  }}
                                >
                                  <img src={phoneIcon} className="phoneIcon" />{' '}
                                  (416) 203-7447{' '}
                                </a>
                              </span>
                            </FlexDiv>
                          </FlexDiv>
                          <FlexDiv
                            className="contactWrapperText"
                            style={{
                              justifyContent: 'flex-end',
                              alignSelf: 'center',
                            }}
                          >
                            <a
                              href="mailto:andy@ellicottrealty.ca"
                              style={{ textDecoration: 'none', display: 'flex' }}
                            >
                              <img src={emailIcon} className="emailIcon" />{' '}
                              <span>Contact</span>
                            </a>
                          </FlexDiv>
                        </FlexDiv>
                        <p>
                          Andy has spent over 12 years working in building, construction and real estate. Born into a family construction business, Andy has been living and breathing real estate for as long as he can remember. Andy launched his first registered Tarion home building company in 2002. His extensive background in property building naturally progressed into real estate, and he began specializing in custom home building in 2003; eventually expanding to incorporate subdivision developments. Andy’s industry knowledge and honest approach to real estate has earned him the loyalty and respect of both clients and colleagues.
                        </p>
                        <button onClick={closeSignupTwo} className="closeModalBtn"><svg xmlns="http://www.w3.org/2000/svg" width="17.688" height="17.688" viewBox="0 0 17.688 17.688"><path id="close-icon" d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z" transform="translate(-7.5 -7.5)" fill="#FF0245"></path></svg></button>
                      </Modal>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={anthonyD} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Anthony Delillo</h3>
                  <p className="teamTitle"> Sales Representative</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        className="phoneNoWrap"
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        className="contactWrapperText"
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:anthonydelillo@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={antonioM} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Antonio Moia</h3>
                  <p className="teamTitle"> Sales Representative</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv className="phoneNoWrap">
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>{' '}
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:antonio@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv
          style={{ flexFlow: 'nowrap', padding: '2em 0' }}
          className="teamMemberRowContainer"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="2000"
        >

          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={Armin} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Armin Ayrom</h3>
                  <p className="teamTitle"> Sales Rep - Condo Specialist</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv className="phoneNoWrap">
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              437-217-5332{' '}
                            </a>
                          </span>
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:aayrom@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>

          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={bita} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Bita Bazoghsa</h3>
                  <p className="teamTitle"> Sales Representative</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>{' '}
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center',
                          padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:bita@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>

          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={danteD} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Dante Dalia</h3>
                  <p className="teamTitle"> VP Operations</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>{' '}
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:dante@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>

          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={davidM} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> David Mendel</h3>
                  <p className="teamTitle"> Commercial Broker </p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        className="phoneNoWrap"
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>{' '}
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        className="contactWrapperText"
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:davidm@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                      <FlexDiv className="bioReadBtn" onClick={openSignupThree}><span>View Bio</span></FlexDiv>
                      <Modal isOpen={signupIsOpenThree} onRequestClose={closeSignupThree} className={'Modal23 bioModal'}>
                        <TeamImg src={davidM} className="memberPhoto" />
                        <h2>David Mendel</h2>
                        <small>Commercial Broker</small>
                        <hr />
                        <FlexDiv
                          style={{ marginTop: '1em' }}
                          className="teamCardBrk modalContactWrapTitle"
                        >
                          <FlexDiv
                            className="phoneNoWrap"
                            style={{
                              flexFlow: 'column',
                              alignSelf: 'center',
                              padding: '0.75em 0.25em 0.9em',
                            }}
                          >
                            <FlexDiv>
                              <span className="">
                                <a
                                  href="tel:647-928-6729"
                                  style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                  }}
                                >
                                  <img src={phoneIcon} className="phoneIcon" />{' '}
                                  (647) 928-6729{' '}
                                </a>
                              </span>
                            </FlexDiv>
                          </FlexDiv>
                          <FlexDiv
                            className="contactWrapperText"
                            style={{
                              justifyContent: 'flex-end',
                              alignSelf: 'center',
                            }}
                          >
                            <a
                              href="mailto:davidm@ellicottrealty.ca"
                              style={{ textDecoration: 'none', display: 'flex' }}
                            >
                              <img src={emailIcon} className="emailIcon" />{' '}
                              <span>Contact</span>
                            </a>
                          </FlexDiv>
                        </FlexDiv>
                        <p>
                          David Mendel’s professional career can be described as all things real estate. Facets ranging from development, operations, financial services, property management and sales; David’s vocation has been to continuously grow within the real estate industry. David began his career working for an engineering consulting firm. This foundation of knowledge allowed him to become a key asset in the next chapter of his career, property management. It was here, where David truly excelled and where he worked his way up to the VP of Operations. Having gathered years of experience in both theory and hands on, he created his own property management company (Somerstone Property Management Inc. est. 2000.). Its continued growth and expansion is how David became proficient in analyzing potential acquisitions, while ensuring his due diligence process brought the capital required to expand. The expansion of the business has brought lasting relationships with investors, builders and municipalities. David’s business aspiration is to gainful opportunities and bring wealth to those that trust in the relationship.
                        </p>
                        <p>
                          Since 2010, David has earned many sales awards with top local brokerages in Toronto. His proven track record of selling properties to new home developers, and successfully assisting them along the way speaks to David’s prowess and the benefit he precedes to his relationships.
                        </p>
                        <div className="awardImgWrapper">
                          <img src={CommercialAntler21} />
                          <img src={PlatinumAntler21} />
                          <img src={GoldAward} className="hiddenOpacity" />
                        </div>
                        <div className="awardImgWrapper rightAward">
                          <img src={GoldAntler20} />
                          <img src={GoldAward} className="hiddenOpacity" />
                        </div>
                        <button onClick={closeSignupThree} className="closeModalBtn"><svg xmlns="http://www.w3.org/2000/svg" width="17.688" height="17.688" viewBox="0 0 17.688 17.688"><path id="close-icon" d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z" transform="translate(-7.5 -7.5)" fill="#FF0245"></path></svg></button>
                      </Modal>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={diana} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Diana DiClemente</h3>
                  <p className="teamTitle"> Office Administrator</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:dianad@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv
          style={{ flexFlow: 'nowrap', padding: '2em 0' }}
          className="teamMemberRowContainer"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="2000"
        >

          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={frankO} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Frank Onorati</h3>
                  <p className="teamTitle"> Sales Representative</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:frankonorati@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>

          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={giancarloF} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Giancarlo Ferrari</h3>
                  <p className="teamTitle"> Sales Representative</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:giancarloferrari@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={ivanI} />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Ivan Ivanov</h3>
                  <p className="teamTitle"> Sales Representative</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:ivanivanov@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={johnR} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> John Rumboldt</h3>
                  <p className="teamTitle"> Executive VP Commercial</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:905-751-3449"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              905-751-3449{' '}
                            </a>
                          </span>
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:johnr@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={mariaL} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Maria Litvak</h3>
                  <p className="teamTitle"> Sales Representative</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        className="phoneNoWrap"
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>{' '}
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        className="contactWrapperText"
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:maria@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                      <FlexDiv className="bioReadBtn" onClick={openSignupSix}><span>View Bio</span></FlexDiv>
                      <Modal isOpen={signupIsOpenSix} onRequestClose={closeSignupSix} className={'Modal23 bioModal'}>
                        <TeamImg src={mariaL} className="memberPhoto" />
                        <h2>MARIA LITVAK</h2>
                        <small>Sales Representative</small>
                        <hr />
                        <FlexDiv
                          style={{ marginTop: '1em' }}
                          className="teamCardBrk modalContactWrapTitle"
                        >
                          <FlexDiv
                            className="phoneNoWrap"
                            style={{
                              flexFlow: 'column',
                              alignSelf: 'center',
                              padding: '0.75em 0.25em 0.9em',
                            }}
                          >
                            <FlexDiv>
                              <span className="">
                                <a
                                  href="tel:416-213-7447"
                                  style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                  }}
                                >
                                  <img src={phoneIcon} className="phoneIcon" />{' '}
                                  (416) 203-7447{' '}
                                </a>
                              </span>
                            </FlexDiv>
                          </FlexDiv>
                          <FlexDiv
                            className="contactWrapperText"
                            style={{
                              justifyContent: 'flex-end',
                              alignSelf: 'center',
                            }}
                          >
                            <a
                              href="mailto:maria@ellicottrealty.ca"
                              style={{ textDecoration: 'none', display: 'flex' }}
                            >
                              <img src={emailIcon} className="emailIcon" />{' '}
                              <span>Contact</span>
                            </a>
                          </FlexDiv>
                        </FlexDiv>
                        <p>Maria began her career as an owner/operator of multi-site gas and convenience outlets that eventually
                          led to a prominent position as a real estate manager of Canada’s largest and one of North America’s
                          fastest growing independent supplier and marketer of petroleum products and a leading convenience
                          store operator.  During a career that spans over two decades, Maria has established her reputation for
                          building relationships with her clients both in commercial and residential practice.  She has a long record
                          of successfully guiding local and national commercial real estate acquisitions, lease obligations and
                          divestments.  As a Richmond Hill resident, Maria has an intimate knowledge of the GTA market.  She
                          ensures all objectives and expectations are exceeded with an expansive network, unparalleled client
                          service and proven marketing strategies.  As a wife and mother of 2, she is a compassionate and
                          trustworthy real estate sales representative who is willing to go above and beyond.</p>


                        <button onClick={closeSignupSix} className="closeModalBtn"><svg xmlns="http://www.w3.org/2000/svg" width="17.688" height="17.688" viewBox="0 0 17.688 17.688"><path id="close-icon" d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z" transform="translate(-7.5 -7.5)" fill="#FF0245"></path></svg></button>
                      </Modal>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv
          style={{ flexFlow: 'nowrap', padding: '2em 0' }}
          className="teamMemberRowContainer"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="2000"
        >



          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={max} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Max Azzopardi</h3>
                  <p className="teamTitle">Commercial Sales Rep</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:maxazzopardi@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>

          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={raffaeleA} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Raffaele Albano</h3>
                  <p className="teamTitle"> Sales Representative</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:ralbano@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={staceyG} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Stacey Goldberg</h3>
                  <p className="teamTitle"> Marketing Manager</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        className="phoneNoWrap"
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>{' '}
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        className="contactWrapperText"
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:stacey@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                      <FlexDiv className="bioReadBtn" onClick={openSignupFour}><span>View Bio</span></FlexDiv>
                      <Modal isOpen={signupIsOpenFour} onRequestClose={closeSignupFour} className={'Modal23 bioModal'}>
                        <TeamImg src={staceyG} className="memberPhoto" />
                        <h2>STACEY GOLDBERG</h2>
                        <small>Marketing Manager</small>
                        <hr />
                        <FlexDiv
                          style={{ marginTop: '1em' }}
                          className="teamCardBrk modalContactWrapTitle"
                        >
                          <FlexDiv
                            className="phoneNoWrap"
                            style={{
                              flexFlow: 'column',
                              alignSelf: 'center',
                              padding: '0.75em 0.25em 0.9em',
                            }}
                          >
                            <FlexDiv>
                              <span className="">
                                <a
                                  href="tel:416-213-7447"
                                  style={{
                                    textDecoration: 'none',
                                    color: 'inherit',
                                  }}
                                >
                                  <img src={phoneIcon} className="phoneIcon" />{' '}
                                  (416) 203-7447{' '}
                                </a>
                              </span>
                            </FlexDiv>
                          </FlexDiv>
                          <FlexDiv
                            className="contactWrapperText"
                            style={{
                              justifyContent: 'flex-end',
                              alignSelf: 'center',
                            }}
                          >
                            <a
                              href="mailto:stacey@ellicottrealty.ca"
                              style={{ textDecoration: 'none', display: 'flex' }}
                            >
                              <img src={emailIcon} className="emailIcon" />{' '}
                              <span>Contact</span>
                            </a>
                          </FlexDiv>
                        </FlexDiv>
                        <p>
                          Stacey is an accomplished Marketing Specialist and Graphic Designer. Her work experience has primarily been focused in and on the real estate industry. This is where she helps brokerages and Realtors elevate their business and brand to the next level. </p>
                        <p>Since 2018, Stacey has been working closely with Ellicott Realty’s team of Realtors. In this role she helps to successfully market their properties, which in-cludes: creating feature brochures, landing pages, social media ads and  e-blasts. </p>
                        <p>Stacey is instrumental in helping the company maintain and build its boutique image across all print and digital platforms. She also develops logos, presentation collateral, social media advertisements and other marketing material for Ellicott Realty and other Ellicott Group Companies. </p>
                        <p>Prior to joining Ellicott Realty, Stacey was the Marketing Manager at Century 21 Heritage Group Ltd. where she was solely responsible for handling over 700 Realtors with their marketing and design efforts. She also worked to implement new marketing initiatives for the brokerage at the corporate level within the Century 21 Canada system.</p>
                        <div className="awardImgWrapper">
                          <img src={EllicottCavalier} />
                          <img src={BlackAward} className="hiddenOpacity" />
                        </div>
                        <button onClick={closeSignupFour} className="closeModalBtn"><svg xmlns="http://www.w3.org/2000/svg" width="17.688" height="17.688" viewBox="0 0 17.688 17.688"><path id="close-icon" d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z" transform="translate(-7.5 -7.5)" fill="#FF0245"></path></svg></button>
                      </Modal>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={steveK} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Steve Kozovski</h3>
                  <p className="teamTitle"> Commercial Sales Rep</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:steve@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>
          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={tamaraA} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Tamara Aminov</h3>
                  <p className="teamTitle"> Commercial Sales Rep</p>
                  <FlexDiv
                    style={{ marginTop: '1em' }}
                    className="teamCardBrk"
                  >
                    <FlexDiv
                      className="phoneNoWrap"
                      style={{
                        flexFlow: 'column',
                        alignSelf: 'center',
                        padding: '0.75em 0.25em 0.9em',
                      }}
                    >
                      <FlexDiv>
                        <span className="">
                          <a
                            href="tel:416-564-0404"
                            style={{
                              textDecoration: 'none',
                              color: 'inherit',
                            }}
                          >
                            <img src={phoneIcon} className="phoneIcon" />{' '}
                            (416) 564-0404{' '}
                          </a>
                        </span>{' '}
                      </FlexDiv>
                    </FlexDiv>
                    <FlexDiv
                      className="contactWrapperText"
                      style={{
                        justifyContent: 'flex-end',
                        alignSelf: 'center',
                      }}
                    >
                      <a
                        href="mailto:tamara@ellicottrealty.ca"
                        style={{ textDecoration: 'none', display: 'flex' }}
                      >
                        <img src={emailIcon} className="emailIcon" />{' '}
                        <span>Contact</span>
                      </a>
                    </FlexDiv>
                    <FlexDiv className="bioReadBtn" onClick={openSignupSeven}><span>View Bio</span></FlexDiv>
                    <Modal isOpen={signupIsOpenSeven} onRequestClose={closeSignupSeven} className={'Modal23 bioModal'}>
                      <TeamImg src={tamaraA} className="memberPhoto" />
                      <h2>Tamara Aminov</h2>
                      <small>Commercial Sales Rep</small>
                      <hr />
                      <FlexDiv
                        style={{ marginTop: '1em' }}
                        className="teamCardBrk modalContactWrapTitle"
                      >
                        <FlexDiv
                          className="phoneNoWrap"
                          style={{
                            flexFlow: 'column',
                            alignSelf: 'center',
                            padding: '0.75em 0.25em 0.9em',
                          }}
                        >
                          <FlexDiv>
                            <span className="">
                              <a
                                href="tel:416-564-0404"
                                style={{
                                  textDecoration: 'none',
                                  color: 'inherit',
                                }}
                              >
                                <img src={phoneIcon} className="phoneIcon" />{' '}
                                (416) 564-0404{' '}
                              </a>
                            </span>
                          </FlexDiv>
                        </FlexDiv>
                        <FlexDiv
                          className="contactWrapperText"
                          style={{
                            justifyContent: 'flex-end',
                            alignSelf: 'center',
                          }}
                        >
                          <a
                            href="mailto:tamara@ellicottrealty.ca"
                            style={{ textDecoration: 'none', display: 'flex' }}
                          >
                            <img src={emailIcon} className="emailIcon" />{' '}
                            <span>Contact</span>
                          </a>
                        </FlexDiv>
                      </FlexDiv>
                      <p>Tamara Aminov is a Commercial Sales Representative at Ellicott Realty, Brokerage. Her experience and specialization are the sale and acquisition of Commercial and Industrial real estate in Toronto and the GTA; as well as redevelopment lots, land assemblies and multi-unit complexes. In order to assist her clients with all their real estate needs, Tamara continues building and maintaining a unique market practice as well as strong relationships with other qualified, reliable professionals, private land owners and corporate clients. </p>
                      <p> Her approach begins with direct, candid communication and the development of well-founded real estate solutions that are certain to leave her clients knowing that they are doing the right thing and have hired the right broker. With Tamara’s strong track record of successful transactions, her clients trust her with their hard-earned money and know she will navigate them in the right direction.</p>
                      <p> Tamara is a hands-on advisor and will work personally with you and your business, whether negotiating a deal or offering options in the process. She stays fixated on your goals without being pressured in challenging markets or negotiations. As a daughter of entrepreneurs and being educated in real estate, Tamara is passionate about what she does. She respect her clients, their businesses and their goals. Always focused and striving to ensure their dream happens. To be a Top Producer takes an incredible amount of ground work, creativity, commitment and personality. She does not give up which has allowed her clients and her to excel through various market cycles. Let Tamara show you how real estate is done right! </p>
                      <div className="awardImgWrapper">
                        <img src={PlatinumAntler21} />
                        <img src={GoldAward} className="hiddenOpacity" />
                      </div>
                      <button onClick={closeSignupSeven} className="closeModalBtn"><svg xmlns="http://www.w3.org/2000/svg" width="17.688" height="17.688" viewBox="0 0 17.688 17.688"><path id="close-icon" d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z" transform="translate(-7.5 -7.5)" fill="#FF0245"></path></svg></button>
                    </Modal>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>
        </FlexDiv>
        <FlexDiv
          style={{ flexFlow: 'nowrap', padding: '2em 0' }}
          className="teamMemberRowContainer lastTeamRow"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="2000"
        >




          <FlexDiv
            style={{ justifyContent: 'center' }}
            className="teamMemberCard"
          >
            <TeamImg src={tellyP} className="memberPhoto" />
            <FlexDiv style={{ background: '#fff', maxWidth: '90%' }}>
              <CardText style={{ width: '' }} className="textWrapper">
                <FlexDiv style={{ flexDirection: 'column' }}>
                  <h3> Telly Papageorge</h3>
                  <p className="teamTitle"> Sales Representative</p>
                  <FlexDiv>
                    <FlexDiv
                      style={{ marginTop: '1em' }}
                      className="teamCardBrk"
                    >
                      <FlexDiv
                        style={{
                          flexFlow: 'column',
                          padding: '0.75em 0.25em 0.9em',
                        }}
                      >
                        <FlexDiv>
                          <span className="">
                            <a
                              href="tel:416-203-7447"
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <img src={phoneIcon} className="phoneIcon" />{' '}
                              (416) 203-7447{' '}
                            </a>
                          </span>
                        </FlexDiv>
                      </FlexDiv>
                      <FlexDiv
                        style={{
                          justifyContent: 'flex-end',
                          alignSelf: 'center', padding: '0.75em 0.75em 0.75em 0',
                        }}
                      >
                        <a
                          href="mailto:telly@ellicottrealty.ca"
                          style={{ textDecoration: 'none', display: 'flex' }}
                        >
                          <img src={emailIcon} className="emailIcon" />{' '}
                          <span>Contact</span>
                        </a>
                      </FlexDiv>
                    </FlexDiv>
                  </FlexDiv>
                </FlexDiv>
              </CardText>
            </FlexDiv>
          </FlexDiv>
        </FlexDiv>
      </CTAWrapper>
    </div>
  )
}
