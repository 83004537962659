import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { TeamHero } from 'components/TeamHero'
import { Team } from 'components/TeamMembers'
const TeamPage: React.FunctionComponent = () => {
  return (
    <EllicotLayout>
      <TeamHero />
      <Team />
    </EllicotLayout>
  )
}

export default TeamPage
