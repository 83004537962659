import React, { useEffect } from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import AOS from 'aos'
import 'aos/dist/aos.css'

const OurPromiseWrapper = styled.div`
  margin: auto;
  padding: 10em;
  display: flex;
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.1),
      rgba(6, 6, 6, 0.23)
    ),
    url(https://northwhick.netlify.app/ellicot-assets/team/team-ellicott.jpg);
  background-size: cover;
  @media (max-width: 600px) {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.1),
        rgba(6, 6, 6, 0.23)
      ),
      url(https://northwhick.netlify.app/ellicot-assets/contact/ellicot-mobile-hero.jpg);
    padding: 2.5em 0 1.5em !important;
  }
`

// northwhick.netlify.app/ellicot-assets/contact/ellicot-mobile-hero.jpg

const CTATitle = styled.h2`
  font-family: 'Montserrat-ExtraBold';
  color: #fff;
  letter-spacing: 1px;
  font-size: 3.75rem;
  text-align: center;
`

const CTAWrapper = styled.div``

export const TeamHero: React.FunctionComponent = () => {
let AOS;
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require("aos");
    AOS.init({
      once: true,
    });
  }, []);

  useEffect(() => {
    if (AOS) {
      AOS.refresh();
    }
  });
  return (
    <CTAWrapper>
      <OurPromiseWrapper className="teamHero">
        <FlexDiv style={{ flexFlow: 'column', padding: '0.5rem' }}>
          <CTATitle className="mobileHeroTitle"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="2000"
          > #TEAMELLICOTT</CTATitle>
        </FlexDiv>
      </OurPromiseWrapper>
    </CTAWrapper>
  )
}
